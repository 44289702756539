// extracted by mini-css-extract-plugin
export var mt0 = "Cooperation-module--mt-0--2I1eY";
export var pt0 = "Cooperation-module--pt-0--1FU-z";
export var mb0 = "Cooperation-module--mb-0--2sVVA";
export var pb0 = "Cooperation-module--pb-0--2s8Jb";
export var ml0 = "Cooperation-module--ml-0--28JoG";
export var pl0 = "Cooperation-module--pl-0--3JLzK";
export var mr0 = "Cooperation-module--mr-0--3G3BG";
export var pr0 = "Cooperation-module--pr-0--1Muhb";
export var mt5 = "Cooperation-module--mt-5--1Ny0p";
export var pt5 = "Cooperation-module--pt-5--3kXnF";
export var mb5 = "Cooperation-module--mb-5--iTxnA";
export var pb5 = "Cooperation-module--pb-5--JIo6G";
export var ml5 = "Cooperation-module--ml-5--3paSI";
export var pl5 = "Cooperation-module--pl-5--k1jpS";
export var mr5 = "Cooperation-module--mr-5--20QH4";
export var pr5 = "Cooperation-module--pr-5--3YGkC";
export var mt10 = "Cooperation-module--mt-10--q16wi";
export var pt10 = "Cooperation-module--pt-10--1yQdE";
export var mb10 = "Cooperation-module--mb-10--1UDX_";
export var pb10 = "Cooperation-module--pb-10--10_aT";
export var ml10 = "Cooperation-module--ml-10--8Pixy";
export var pl10 = "Cooperation-module--pl-10--KNAGm";
export var mr10 = "Cooperation-module--mr-10--ipW2b";
export var pr10 = "Cooperation-module--pr-10--1OcW4";
export var mt15 = "Cooperation-module--mt-15--2RZol";
export var pt15 = "Cooperation-module--pt-15--AwM35";
export var mb15 = "Cooperation-module--mb-15--1yLu-";
export var pb15 = "Cooperation-module--pb-15--ob9JU";
export var ml15 = "Cooperation-module--ml-15--39lJI";
export var pl15 = "Cooperation-module--pl-15--2fIB0";
export var mr15 = "Cooperation-module--mr-15--2ZuC5";
export var pr15 = "Cooperation-module--pr-15--2J0IZ";
export var mt20 = "Cooperation-module--mt-20--g8UwS";
export var pt20 = "Cooperation-module--pt-20--3-9KK";
export var mb20 = "Cooperation-module--mb-20--2c0_5";
export var pb20 = "Cooperation-module--pb-20--2lQ4T";
export var ml20 = "Cooperation-module--ml-20--2xqMg";
export var pl20 = "Cooperation-module--pl-20--LsEqt";
export var mr20 = "Cooperation-module--mr-20--kO6OR";
export var pr20 = "Cooperation-module--pr-20--Pag9N";
export var mt25 = "Cooperation-module--mt-25--19c7z";
export var pt25 = "Cooperation-module--pt-25--iOoTS";
export var mb25 = "Cooperation-module--mb-25--3nD-X";
export var pb25 = "Cooperation-module--pb-25--3B6PJ";
export var ml25 = "Cooperation-module--ml-25--lUxla";
export var pl25 = "Cooperation-module--pl-25--1TN3S";
export var mr25 = "Cooperation-module--mr-25--26n2J";
export var pr25 = "Cooperation-module--pr-25--h3dup";
export var mt30 = "Cooperation-module--mt-30--s7WDh";
export var pt30 = "Cooperation-module--pt-30--2As_T";
export var mb30 = "Cooperation-module--mb-30--2JOvB";
export var pb30 = "Cooperation-module--pb-30--mH9zi";
export var ml30 = "Cooperation-module--ml-30--3P_Tf";
export var pl30 = "Cooperation-module--pl-30--ALC_s";
export var mr30 = "Cooperation-module--mr-30--1dNkU";
export var pr30 = "Cooperation-module--pr-30--bpeeX";
export var mt35 = "Cooperation-module--mt-35--1GiFd";
export var pt35 = "Cooperation-module--pt-35--1-3pA";
export var mb35 = "Cooperation-module--mb-35--OPvpo";
export var pb35 = "Cooperation-module--pb-35--OpLwF";
export var ml35 = "Cooperation-module--ml-35--3Zo3E";
export var pl35 = "Cooperation-module--pl-35--VxTLY";
export var mr35 = "Cooperation-module--mr-35--3PBPc";
export var pr35 = "Cooperation-module--pr-35--1JYNf";
export var mt40 = "Cooperation-module--mt-40--10yXs";
export var pt40 = "Cooperation-module--pt-40--2A5B6";
export var mb40 = "Cooperation-module--mb-40--ssMMV";
export var pb40 = "Cooperation-module--pb-40--2RmAt";
export var ml40 = "Cooperation-module--ml-40--1wT4e";
export var pl40 = "Cooperation-module--pl-40--1qVWG";
export var mr40 = "Cooperation-module--mr-40--2iHQV";
export var pr40 = "Cooperation-module--pr-40--2tmRI";
export var mt45 = "Cooperation-module--mt-45--_QgUh";
export var pt45 = "Cooperation-module--pt-45--cWVXg";
export var mb45 = "Cooperation-module--mb-45--23yXA";
export var pb45 = "Cooperation-module--pb-45--316xx";
export var ml45 = "Cooperation-module--ml-45--2gIY3";
export var pl45 = "Cooperation-module--pl-45--1NfUe";
export var mr45 = "Cooperation-module--mr-45--1Yo4p";
export var pr45 = "Cooperation-module--pr-45--1ve0c";
export var mt50 = "Cooperation-module--mt-50--106TK";
export var pt50 = "Cooperation-module--pt-50--2fAfe";
export var mb50 = "Cooperation-module--mb-50--1RQ42";
export var pb50 = "Cooperation-module--pb-50--2ekEL";
export var ml50 = "Cooperation-module--ml-50--deTct";
export var pl50 = "Cooperation-module--pl-50--nWWXV";
export var mr50 = "Cooperation-module--mr-50--2ynOO";
export var pr50 = "Cooperation-module--pr-50--3vN7o";
export var mt75 = "Cooperation-module--mt-75--1d5Q_";
export var pt75 = "Cooperation-module--pt-75--3wMmK";
export var mb75 = "Cooperation-module--mb-75--4V-Dv";
export var pb75 = "Cooperation-module--pb-75--jy4OZ";
export var ml75 = "Cooperation-module--ml-75--2sIpE";
export var pl75 = "Cooperation-module--pl-75--3Ez_j";
export var mr75 = "Cooperation-module--mr-75--26xJ6";
export var pr75 = "Cooperation-module--pr-75--1D_a6";
export var mt100 = "Cooperation-module--mt-100--35uU8";
export var pt100 = "Cooperation-module--pt-100--3jorb";
export var mb100 = "Cooperation-module--mb-100--2IVrc";
export var pb100 = "Cooperation-module--pb-100--3enSr";
export var ml100 = "Cooperation-module--ml-100--2EIiB";
export var pl100 = "Cooperation-module--pl-100--3BX79";
export var mr100 = "Cooperation-module--mr-100--1WrfN";
export var pr100 = "Cooperation-module--pr-100--_nQP4";
export var zIndex1 = "Cooperation-module--z-index-1--yjnyR";
export var zIndex2 = "Cooperation-module--z-index-2--m4FIZ";
export var zIndex3 = "Cooperation-module--z-index-3--2TJj7";
export var zIndex4 = "Cooperation-module--z-index-4--3n0By";
export var zIndex5 = "Cooperation-module--z-index-5--2sxYL";
export var zIndex6 = "Cooperation-module--z-index-6--2Bt7W";
export var zIndex7 = "Cooperation-module--z-index-7--3eT-E";
export var zIndex8 = "Cooperation-module--z-index-8--ME3D0";
export var zIndex9 = "Cooperation-module--z-index-9--3AsQi";
export var zIndex10 = "Cooperation-module--z-index-10--2IcGy";
export var zIndex20 = "Cooperation-module--z-index-20--26I9N";
export var zIndex30 = "Cooperation-module--z-index-30--2fVmJ";
export var zIndex40 = "Cooperation-module--z-index-40--1h77O";
export var zIndex50 = "Cooperation-module--z-index-50--3r3D0";
export var zIndex60 = "Cooperation-module--z-index-60--1Ljbn";
export var zIndex70 = "Cooperation-module--z-index-70--hx_oN";
export var zIndex80 = "Cooperation-module--z-index-80--1fjGo";
export var zIndex90 = "Cooperation-module--z-index-90--2dmL2";
export var zIndex100 = "Cooperation-module--z-index-100--2HQSN";
export var link = "Cooperation-module--link--1W-D8";
export var uppercase = "Cooperation-module--uppercase--39EiG";
export var more = "Cooperation-module--more--V95UM";
export var button = "Cooperation-module--button--2a28A";
export var send = "Cooperation-module--send--3z8Vc";
export var sm = "Cooperation-module--sm--367hi";
export var cooperationWrap = "Cooperation-module--cooperationWrap--2yhmk";
export var sectionShadowTitle = "Cooperation-module--sectionShadowTitle--14cVS";
export var contentWrap = "Cooperation-module--contentWrap--13ESH";
export var descriptionWrap = "Cooperation-module--descriptionWrap--2Dqi0";
export var title = "Cooperation-module--title--3tFvZ";
export var description = "Cooperation-module--description--2eBfE";
export var specialityWrap = "Cooperation-module--specialityWrap--2bzvL";
export var list = "Cooperation-module--list--28JP7";
export var listItem = "Cooperation-module--listItem--qkSba";
export var icon = "Cooperation-module--icon--21lf2";
export var itemTitle = "Cooperation-module--itemTitle--3WRbG";
export var photoWrap = "Cooperation-module--photoWrap--12cxd";